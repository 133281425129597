@media (max-width: 320px + 50px) {
  .media-test {
    display: 'xs';
  }
}
@media (max-width: 544px) {
  .media-test {
    display: 'mobile';
  }
}
@media (max-width: 864px) {
  .media-test {
    display: 'tabletSmall';
  }
}
@media (max-width: 1030px) {
  .media-test {
    display: 'tablet';
  }
}
@media (max-width: 1233px) {
  .media-test {
    display: 'desktopSmall';
  }
}
@media (max-width: 1643px) {
  .media-test {
    display: 'desktop';
  }
}
@font-face {
  /*font-weight: 700;*/
  font-style: normal;
  font-family: 'suisseIntlBold';
  src: url("/fonts/SuisseIntlBold/SuisseIntl-Bold.eot") format("eot"), url("/fonts/SuisseIntlBold/SuisseIntl-Bold.woff2") format("woff2"), url("/fonts/SuisseIntlBold/SuisseIntl-Bold.woff") format("woff"), url("/fonts/SuisseIntlBold/SuisseIntl-Bold.ttf") format("truetype");
}
@font-face {
  /*font-weight: 500;*/
  font-style: normal;
  font-family: 'suisseIntlBook';
  src: url("/fonts/SuisseIntlBook/SuisseIntl-Book.eot") format("eot"), url("/fonts/SuisseIntlBook/SuisseIntl-Book.woff2") format("woff2"), url("/fonts/SuisseIntlBook/SuisseIntl-Book.woff") format("woff"), url("/fonts/SuisseIntlBook/SuisseIntl-Book.ttf") format("truetype");
}
@font-face {
  /*font-weight: 300;*/
  font-style: normal;
  font-family: 'suisseIntlMedium';
  src: url("/fonts/SuisseIntlMedium/SuisseIntl-Medium.eot") format("eot"), url("/fonts/SuisseIntlMedium/SuisseIntl-Medium.woff2") format("woff2"), url("/fonts/SuisseIntlMedium/SuisseIntl-Medium.woff") format("woff"), url("/fonts/SuisseIntlMedium/SuisseIntl-Medium.ttf") format("truetype");
}
@font-face {
  font-style: normal;
  font-weight: 400;
  font-family: 'merriweather';
  src: url("/fonts/MerriweatherRegular/Merriweather-Regular.eot") format("eot"), url("/fonts/MerriweatherRegular/Merriweather-Regular.woff2") format("woff2"), url("/fonts/MerriweatherRegular/Merriweather-Regular.woff") format("woff"), url("/fonts/MerriweatherRegular/Merriweather-Regular.ttf") format("truetype");
}
@font-face {
  font-style: italic;
  font-weight: 400;
  font-family: 'merriweather';
  src: url("/fonts/MerriweatherItalic/Merriweather-Italic.eot") format("eot"), url("/fonts/MerriweatherItalic/Merriweather-Italic.woff2") format("woff2"), url("/fonts/MerriweatherItalic/Merriweather-Italic.woff") format("woff"), url("/fonts/MerriweatherItalic/Merriweather-Italic.ttf") format("truetype");
}
@font-face {
  font-style: normal;
  font-weight: 900;
  font-family: 'merriweather';
  src: url("/fonts/MerriweatherBlack/Merriweather-Black.eot") format("eot"), url("/fonts/MerriweatherBlack/Merriweather-Black.woff2") format("woff2"), url("/fonts/MerriweatherBlack/Merriweather-Black.woff") format("woff"), url("/fonts/MerriweatherBlack/Merriweather-Black.ttf") format("truetype");
}
.input {
  margin-top: 15px;
  padding: 18px 15px;
  display: block;
  width: 100%;
  height: 56px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 15px;
  line-height: 20px;
  font-family: 'suisseIntlBook', sans-serif;
  color: #333333;
  transition: border-color 0.2s;
  -webkit-appearance: none;
  border-radius: 0;
}
.input_textarea {
  height: 150px;
  resize: none;
}
.input:hover,
.input:focus {
  border-color: #00BFFF;
}
.input_error {
  border-color: #F56762;
}
.input__label {
  display: block;
  margin-top: 25px;
  font-size: 14px;
  line-height: 19px;
  color: #999999;
  font-family: 'suisseIntlBook', sans-serif;
}
.input__label:first-child {
  margin-top: 0;
}
.input__container {
  margin-top: 15px;
  display: flex;
  width: 100%;
}
.input__file {
  flex-grow: 1;
  padding: 17px 15px;
  font-size: 15px;
  line-height: 20px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  font-family: 'suisseIntlBook', sans-serif;
  color: #333333;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  -webkit-appearance: none;
  border-radius: 0;
}
.input__button {
  min-width: 40%;
  -webkit-appearance: none;
  border-radius: 0;
}
.input__help {
  margin: 0;
  margin-top: 15px;
  font-size: 14px;
  line-height: 19px;
  color: #333333;
  font-family: 'suisseIntlBook', sans-serif;
}
.input__tip {
  margin-top: 13px;
  font-size: 12px;
  line-height: 18px;
  color: #F56762;
}
