@media (max-width: 320px + 50px) {
  .media-test {
    display: 'xs';
  }
}
@media (max-width: 544px) {
  .media-test {
    display: 'mobile';
  }
}
@media (max-width: 864px) {
  .media-test {
    display: 'tabletSmall';
  }
}
@media (max-width: 1030px) {
  .media-test {
    display: 'tablet';
  }
}
@media (max-width: 1233px) {
  .media-test {
    display: 'desktopSmall';
  }
}
@media (max-width: 1643px) {
  .media-test {
    display: 'desktop';
  }
}
@font-face {
  /*font-weight: 700;*/
  font-style: normal;
  font-family: 'suisseIntlBold';
  src: url("/fonts/SuisseIntlBold/SuisseIntl-Bold.eot") format("eot"), url("/fonts/SuisseIntlBold/SuisseIntl-Bold.woff2") format("woff2"), url("/fonts/SuisseIntlBold/SuisseIntl-Bold.woff") format("woff"), url("/fonts/SuisseIntlBold/SuisseIntl-Bold.ttf") format("truetype");
}
@font-face {
  /*font-weight: 500;*/
  font-style: normal;
  font-family: 'suisseIntlBook';
  src: url("/fonts/SuisseIntlBook/SuisseIntl-Book.eot") format("eot"), url("/fonts/SuisseIntlBook/SuisseIntl-Book.woff2") format("woff2"), url("/fonts/SuisseIntlBook/SuisseIntl-Book.woff") format("woff"), url("/fonts/SuisseIntlBook/SuisseIntl-Book.ttf") format("truetype");
}
@font-face {
  /*font-weight: 300;*/
  font-style: normal;
  font-family: 'suisseIntlMedium';
  src: url("/fonts/SuisseIntlMedium/SuisseIntl-Medium.eot") format("eot"), url("/fonts/SuisseIntlMedium/SuisseIntl-Medium.woff2") format("woff2"), url("/fonts/SuisseIntlMedium/SuisseIntl-Medium.woff") format("woff"), url("/fonts/SuisseIntlMedium/SuisseIntl-Medium.ttf") format("truetype");
}
@font-face {
  font-style: normal;
  font-weight: 400;
  font-family: 'merriweather';
  src: url("/fonts/MerriweatherRegular/Merriweather-Regular.eot") format("eot"), url("/fonts/MerriweatherRegular/Merriweather-Regular.woff2") format("woff2"), url("/fonts/MerriweatherRegular/Merriweather-Regular.woff") format("woff"), url("/fonts/MerriweatherRegular/Merriweather-Regular.ttf") format("truetype");
}
@font-face {
  font-style: italic;
  font-weight: 400;
  font-family: 'merriweather';
  src: url("/fonts/MerriweatherItalic/Merriweather-Italic.eot") format("eot"), url("/fonts/MerriweatherItalic/Merriweather-Italic.woff2") format("woff2"), url("/fonts/MerriweatherItalic/Merriweather-Italic.woff") format("woff"), url("/fonts/MerriweatherItalic/Merriweather-Italic.ttf") format("truetype");
}
@font-face {
  font-style: normal;
  font-weight: 900;
  font-family: 'merriweather';
  src: url("/fonts/MerriweatherBlack/Merriweather-Black.eot") format("eot"), url("/fonts/MerriweatherBlack/Merriweather-Black.woff2") format("woff2"), url("/fonts/MerriweatherBlack/Merriweather-Black.woff") format("woff"), url("/fonts/MerriweatherBlack/Merriweather-Black.ttf") format("truetype");
}
.footer {
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #232323;
  flex-shrink: 0;
}
.footer__container {
  padding-bottom: 40px;
}
@media (min-width: 544px) {
  .footer__container {
    display: flex;
    justify-content: space-between;
  }
}
.footer__container_rules {
  padding-top: 16px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}
@media (min-width: 544px) {
  .footer__menu {
    width: 216px;
  }
}
.footer__item {
  margin-right: 24px;
  margin-bottom: 20px;
  display: inline-block;
  color: white;
  text-decoration: none;
  transition: color .3s;
  font-size: 14px;
  line-height: 20px;
}
@media (min-width: 544px) {
  .footer__item {
    font-size: 15px;
    margin-right: 0;
    display: block;
  }
}
.footer__item:hover {
  color: #999;
}
.footer__rules {
  margin-right: 30px;
  color: white;
  text-decoration: none;
  transition: color .3s;
  font-size: 14px;
  line-height: 20px;
}
.footer__rules:hover {
  color: #999;
}
@media (min-width: 544px) {
  .footer__rules {
    font-size: 15px;
  }
}
@media (min-width: 544px) {
  .footer__information {
    width: 50%;
  }
}
.footer__text {
  color: #999;
  font-size: 14px;
  line-height: 20px;
}
@media (min-width: 544px) {
  .footer__text {
    font-size: 15px;
  }
}
@media (min-width: 544px) {
  .footer__text_main {
    padding-bottom: 34px;
  }
}
.footer__text_copyright {
  margin-right: 24px;
}
.footer__text_who,
.footer__text_copyright,
.footer__text_year {
  display: block;
  margin-top: 20px;
}
@media (min-width: 544px) {
  .footer__text_who,
  .footer__text_copyright,
  .footer__text_year {
    margin-top: 0;
    display: inline;
  }
}
