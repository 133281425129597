@font-face {
    /*font-weight: 700;*/
    font-style: normal;
    font-family: 'suisseIntlBold';
    src: url("/fonts/SuisseIntlBold/SuisseIntl-Bold.eot") format("eot"),
    url("/fonts/SuisseIntlBold/SuisseIntl-Bold.woff2") format("woff2"),
    url("/fonts/SuisseIntlBold/SuisseIntl-Bold.woff") format("woff"),
    url("/fonts/SuisseIntlBold/SuisseIntl-Bold.ttf") format("truetype");
}

@font-face {
    /*font-weight: 500;*/
    font-style: normal;
    font-family: 'suisseIntlBook';
    src: url("/fonts/SuisseIntlBook/SuisseIntl-Book.eot") format("eot"),
    url("/fonts/SuisseIntlBook/SuisseIntl-Book.woff2") format("woff2"),
    url("/fonts/SuisseIntlBook/SuisseIntl-Book.woff") format("woff"),
    url("/fonts/SuisseIntlBook/SuisseIntl-Book.ttf") format("truetype");
}

@font-face {
    /*font-weight: 300;*/
    font-style: normal;
    font-family: 'suisseIntlMedium';
    src: url("/fonts/SuisseIntlMedium/SuisseIntl-Medium.eot") format("eot"),
    url("/fonts/SuisseIntlMedium/SuisseIntl-Medium.woff2") format("woff2"),
    url("/fonts/SuisseIntlMedium/SuisseIntl-Medium.woff") format("woff"),
    url("/fonts/SuisseIntlMedium/SuisseIntl-Medium.ttf") format("truetype");
}

@font-face {
    font-style: normal;
    font-weight: 400;
    font-family: 'merriweather';
    src: url("/fonts/MerriweatherRegular/Merriweather-Regular.eot") format("eot"),
    url("/fonts/MerriweatherRegular/Merriweather-Regular.woff2") format("woff2"),
    url("/fonts/MerriweatherRegular/Merriweather-Regular.woff") format("woff"),
    url("/fonts/MerriweatherRegular/Merriweather-Regular.ttf") format("truetype");
}

@font-face {
    font-style: italic;
    font-weight: 400;
    font-family: 'merriweather';
    src: url("/fonts/MerriweatherItalic/Merriweather-Italic.eot") format("eot"),
    url("/fonts/MerriweatherItalic/Merriweather-Italic.woff2") format("woff2"),
    url("/fonts/MerriweatherItalic/Merriweather-Italic.woff") format("woff"),
    url("/fonts/MerriweatherItalic/Merriweather-Italic.ttf") format("truetype");
}

@font-face {
    font-style: normal;
    font-weight: 900;
    font-family: 'merriweather';
    src: url("/fonts/MerriweatherBlack/Merriweather-Black.eot") format("eot"),
    url("/fonts/MerriweatherBlack/Merriweather-Black.woff2") format("woff2"),
    url("/fonts/MerriweatherBlack/Merriweather-Black.woff") format("woff"),
    url("/fonts/MerriweatherBlack/Merriweather-Black.ttf") format("truetype");
}
