@import "../app";

.header {
    position: fixed;
    height: @headerHeightMobile;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: @layerHeader;
    background-color: white;
    flex-shrink: 0;

    .mobileBack({
        height: @headerHeight;
    });

    &__menu {
        display: flex;
        align-items: center;
        height: 64px;
        background-color: #2059F6;

        .mobileBack({
            height: 80px;
            background-color: white;
        });
    }

    &__logo {
        display: block;
        width: 212px;
        height: 36px;
        margin-bottom: 2px;
        margin-right: 24px;
        text-decoration: none;
        outline: 0;
        .contain();
        .image('logo_white.svg');
        flex-shrink: 0;

        .mobileBack({
            .image('logo_blue.svg');
        });
    }

    &__line {
        display: none;
        background-color: #E9E9E9;
        height: 100%;
        width: 1px;

        .mobileBack({
            display: block;
        });
    }

    &__news {
        display: none;
        margin-left: 32px;
        padding-right: 30px;
        position: relative;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 25px;
        color: #333333;
        .sans-serif();
        text-decoration: none;

        .mobileBack({
            display: block;
        });

        &:before {
            position: absolute;
            right: 8px;
            top: 50%;
            content: '';
            display: block;
            width: 18px;
            height: 18px;
            transform: translateY(-50%);
            .contain();
            .image('arrow.svg');
            transition: transform 0.3s;
        }

        &:hover {
            &:before {
                transform: translate(8px, -50%);
            }
        }
    }

    &__background {
        position: relative;
        display: flex;
        align-items: center;
        background-color: #2059F6;
        height: 55px;
        overflow: hidden;

        .mobileBack({
            height: 64px;
        });
    }

    &__container {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
    }

    &__title {
        position: relative;
        width: 307px;
        height: 50px;
        flex-shrink: 0;
        .contain();
        .image('curve-line-new.svg');
        z-index: 1;
        background-color: #2059F6;
    }

    &__subtitle {
        margin-top: 0.5px;
        flex-grow: 1;
        width: 0;
        height: 0.5px;
        background-color: white;

        &_second {
            position: absolute;
            right: 0;
            width: 50%;
        }
    }
}
