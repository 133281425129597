@import "../app";

.input {
    margin-top: 15px;
    padding: 18px 15px;
    display: block;
    width: 100%;
    height: @buttonSize;
    border: 1px solid rgba(0, 0, 0, 0.1);
    font-size: 15px;
    line-height: 20px;
    .sans-serif();
    color: #333333;
    transition: border-color .2s;
    -webkit-appearance: none;
    border-radius: 0;

    &_textarea {
        height: 150px;
        resize: none;
    }

    &:hover,
    &:focus {
        border-color: #00BFFF;
    }

    &_error {
        border-color: #F56762;
    }

    &__label {
        display: block;
        margin-top: 25px;
        font-size: 14px;
        line-height: 19px;
        color: #999999;
        .sans-serif();

        &:first-child {
            margin-top: 0;
        }
    }

    &__container {
        margin-top: 15px;
        display: flex;
        width: 100%;
    }

    &__file {
        flex-grow: 1;
        padding: 17px 15px;
        font-size: 15px;
        line-height: 20px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        .sans-serif();
        color: #333333;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        -webkit-appearance: none;
        border-radius: 0;
    }

    &__button {
        min-width: 40%;
        -webkit-appearance: none;
        border-radius: 0;
    }

    &__help {
        margin: 0;
        margin-top: 15px;
        font-size: 14px;
        line-height: 19px;
        color: #333333;
        .sans-serif();
    }

    &__tip {
        margin-top: 13px;
        font-size: 12px;
        line-height: 18px;
        color: #F56762;
    }
}
