@media (max-width: 320px + 50px) {
  .media-test {
    display: 'xs';
  }
}
@media (max-width: 544px) {
  .media-test {
    display: 'mobile';
  }
}
@media (max-width: 864px) {
  .media-test {
    display: 'tabletSmall';
  }
}
@media (max-width: 1030px) {
  .media-test {
    display: 'tablet';
  }
}
@media (max-width: 1233px) {
  .media-test {
    display: 'desktopSmall';
  }
}
@media (max-width: 1643px) {
  .media-test {
    display: 'desktop';
  }
}
@font-face {
  /*font-weight: 700;*/
  font-style: normal;
  font-family: 'suisseIntlBold';
  src: url("/fonts/SuisseIntlBold/SuisseIntl-Bold.eot") format("eot"), url("/fonts/SuisseIntlBold/SuisseIntl-Bold.woff2") format("woff2"), url("/fonts/SuisseIntlBold/SuisseIntl-Bold.woff") format("woff"), url("/fonts/SuisseIntlBold/SuisseIntl-Bold.ttf") format("truetype");
}
@font-face {
  /*font-weight: 500;*/
  font-style: normal;
  font-family: 'suisseIntlBook';
  src: url("/fonts/SuisseIntlBook/SuisseIntl-Book.eot") format("eot"), url("/fonts/SuisseIntlBook/SuisseIntl-Book.woff2") format("woff2"), url("/fonts/SuisseIntlBook/SuisseIntl-Book.woff") format("woff"), url("/fonts/SuisseIntlBook/SuisseIntl-Book.ttf") format("truetype");
}
@font-face {
  /*font-weight: 300;*/
  font-style: normal;
  font-family: 'suisseIntlMedium';
  src: url("/fonts/SuisseIntlMedium/SuisseIntl-Medium.eot") format("eot"), url("/fonts/SuisseIntlMedium/SuisseIntl-Medium.woff2") format("woff2"), url("/fonts/SuisseIntlMedium/SuisseIntl-Medium.woff") format("woff"), url("/fonts/SuisseIntlMedium/SuisseIntl-Medium.ttf") format("truetype");
}
@font-face {
  font-style: normal;
  font-weight: 400;
  font-family: 'merriweather';
  src: url("/fonts/MerriweatherRegular/Merriweather-Regular.eot") format("eot"), url("/fonts/MerriweatherRegular/Merriweather-Regular.woff2") format("woff2"), url("/fonts/MerriweatherRegular/Merriweather-Regular.woff") format("woff"), url("/fonts/MerriweatherRegular/Merriweather-Regular.ttf") format("truetype");
}
@font-face {
  font-style: italic;
  font-weight: 400;
  font-family: 'merriweather';
  src: url("/fonts/MerriweatherItalic/Merriweather-Italic.eot") format("eot"), url("/fonts/MerriweatherItalic/Merriweather-Italic.woff2") format("woff2"), url("/fonts/MerriweatherItalic/Merriweather-Italic.woff") format("woff"), url("/fonts/MerriweatherItalic/Merriweather-Italic.ttf") format("truetype");
}
@font-face {
  font-style: normal;
  font-weight: 900;
  font-family: 'merriweather';
  src: url("/fonts/MerriweatherBlack/Merriweather-Black.eot") format("eot"), url("/fonts/MerriweatherBlack/Merriweather-Black.woff2") format("woff2"), url("/fonts/MerriweatherBlack/Merriweather-Black.woff") format("woff"), url("/fonts/MerriweatherBlack/Merriweather-Black.ttf") format("truetype");
}
.button {
  line-height: 56px;
  display: inline-block;
  box-sizing: border-box;
  height: 56px;
  padding: 0 10px;
  cursor: pointer;
  user-select: none;
  transition: opacity 0.2s, background-color 0.2s, border-top-color 0.2s, border-bottom-color 0.2s, border-left-color 0.2s, border-right-color 0.2s;
  text-align: center;
  vertical-align: top;
  white-space: nowrap;
  text-decoration: none;
  color: white;
  border: 1px solid #2059F6;
  background-color: #2059F6;
}
.button[disabled] {
  transition: opacity .3s;
  pointer-events: none;
  opacity: .5;
}
.button:hover {
  border: 1px solid #1A47C2;
  background-color: #1A47C2;
}
.button_full {
  display: block;
  width: 100%;
}
.button_mt {
  margin-top: 20px;
}
