@import '../app';

.button
{
    line-height: @buttonSize;

    display: inline-block;

    box-sizing: border-box;
    height: @buttonSize;
    padding: 0 10px;

    cursor: pointer;
    user-select: none;
    transition: opacity .2s, background-color .2s, border-top-color .2s, border-bottom-color .2s, border-left-color .2s, border-right-color .2s;
    text-align: center;
    vertical-align: top;
    white-space: nowrap;
    text-decoration: none;

    color: white;
    border: 1px solid #2059F6;
    background-color: #2059F6;

    &[disabled]
    {
        transition: opacity .3s;
        pointer-events: none;

        opacity: .5;
    }

    &:hover
    {
        border: 1px solid #1A47C2;
        background-color: #1A47C2;
    }


    &_full
    {
        display: block;

        width: 100%;
    }

    &_mt {
        margin-top: 20px;
    }
}
