@media (max-width: 320px + 50px) {
  .media-test {
    display: 'xs';
  }
}
@media (max-width: 544px) {
  .media-test {
    display: 'mobile';
  }
}
@media (max-width: 864px) {
  .media-test {
    display: 'tabletSmall';
  }
}
@media (max-width: 1030px) {
  .media-test {
    display: 'tablet';
  }
}
@media (max-width: 1233px) {
  .media-test {
    display: 'desktopSmall';
  }
}
@media (max-width: 1643px) {
  .media-test {
    display: 'desktop';
  }
}
@font-face {
  /*font-weight: 700;*/
  font-style: normal;
  font-family: 'suisseIntlBold';
  src: url("/fonts/SuisseIntlBold/SuisseIntl-Bold.eot") format("eot"), url("/fonts/SuisseIntlBold/SuisseIntl-Bold.woff2") format("woff2"), url("/fonts/SuisseIntlBold/SuisseIntl-Bold.woff") format("woff"), url("/fonts/SuisseIntlBold/SuisseIntl-Bold.ttf") format("truetype");
}
@font-face {
  /*font-weight: 500;*/
  font-style: normal;
  font-family: 'suisseIntlBook';
  src: url("/fonts/SuisseIntlBook/SuisseIntl-Book.eot") format("eot"), url("/fonts/SuisseIntlBook/SuisseIntl-Book.woff2") format("woff2"), url("/fonts/SuisseIntlBook/SuisseIntl-Book.woff") format("woff"), url("/fonts/SuisseIntlBook/SuisseIntl-Book.ttf") format("truetype");
}
@font-face {
  /*font-weight: 300;*/
  font-style: normal;
  font-family: 'suisseIntlMedium';
  src: url("/fonts/SuisseIntlMedium/SuisseIntl-Medium.eot") format("eot"), url("/fonts/SuisseIntlMedium/SuisseIntl-Medium.woff2") format("woff2"), url("/fonts/SuisseIntlMedium/SuisseIntl-Medium.woff") format("woff"), url("/fonts/SuisseIntlMedium/SuisseIntl-Medium.ttf") format("truetype");
}
@font-face {
  font-style: normal;
  font-weight: 400;
  font-family: 'merriweather';
  src: url("/fonts/MerriweatherRegular/Merriweather-Regular.eot") format("eot"), url("/fonts/MerriweatherRegular/Merriweather-Regular.woff2") format("woff2"), url("/fonts/MerriweatherRegular/Merriweather-Regular.woff") format("woff"), url("/fonts/MerriweatherRegular/Merriweather-Regular.ttf") format("truetype");
}
@font-face {
  font-style: italic;
  font-weight: 400;
  font-family: 'merriweather';
  src: url("/fonts/MerriweatherItalic/Merriweather-Italic.eot") format("eot"), url("/fonts/MerriweatherItalic/Merriweather-Italic.woff2") format("woff2"), url("/fonts/MerriweatherItalic/Merriweather-Italic.woff") format("woff"), url("/fonts/MerriweatherItalic/Merriweather-Italic.ttf") format("truetype");
}
@font-face {
  font-style: normal;
  font-weight: 900;
  font-family: 'merriweather';
  src: url("/fonts/MerriweatherBlack/Merriweather-Black.eot") format("eot"), url("/fonts/MerriweatherBlack/Merriweather-Black.woff2") format("woff2"), url("/fonts/MerriweatherBlack/Merriweather-Black.woff") format("woff"), url("/fonts/MerriweatherBlack/Merriweather-Black.ttf") format("truetype");
}
.header {
  position: fixed;
  height: 119px;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 30;
  background-color: white;
  flex-shrink: 0;
}
@media (min-width: 544px) {
  .header {
    height: 144px;
  }
}
.header__menu {
  display: flex;
  align-items: center;
  height: 64px;
  background-color: #2059F6;
}
@media (min-width: 544px) {
  .header__menu {
    height: 80px;
    background-color: white;
  }
}
.header__logo {
  display: block;
  width: 212px;
  height: 36px;
  margin-bottom: 2px;
  margin-right: 24px;
  text-decoration: none;
  outline: 0;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  background-image: url("/img/logo_white.svg");
  flex-shrink: 0;
}
@media (min-width: 544px) {
  .header__logo {
    background-image: url("/img/logo_blue.svg");
  }
}
.header__line {
  display: none;
  background-color: #E9E9E9;
  height: 100%;
  width: 1px;
}
@media (min-width: 544px) {
  .header__line {
    display: block;
  }
}
.header__news {
  display: none;
  margin-left: 32px;
  padding-right: 30px;
  position: relative;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  color: #333333;
  font-family: 'suisseIntlBook', sans-serif;
  text-decoration: none;
}
@media (min-width: 544px) {
  .header__news {
    display: block;
  }
}
.header__news:before {
  position: absolute;
  right: 8px;
  top: 50%;
  content: '';
  display: block;
  width: 18px;
  height: 18px;
  transform: translateY(-50%);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  background-image: url("/img/arrow.svg");
  transition: transform 0.3s;
}
.header__news:hover:before {
  transform: translate(8px, -50%);
}
.header__background {
  position: relative;
  display: flex;
  align-items: center;
  background-color: #2059F6;
  height: 55px;
  overflow: hidden;
}
@media (min-width: 544px) {
  .header__background {
    height: 64px;
  }
}
.header__container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}
.header__title {
  position: relative;
  width: 307px;
  height: 50px;
  flex-shrink: 0;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  background-image: url("/img/curve-line-new.svg");
  z-index: 1;
  background-color: #2059F6;
}
.header__subtitle {
  margin-top: 0.5px;
  flex-grow: 1;
  width: 0;
  height: 0.5px;
  background-color: white;
}
.header__subtitle_second {
  position: absolute;
  right: 0;
  width: 50%;
}
