@media (max-width: 320px + 50px) {
  .media-test {
    display: 'xs';
  }
}
@media (max-width: 544px) {
  .media-test {
    display: 'mobile';
  }
}
@media (max-width: 864px) {
  .media-test {
    display: 'tabletSmall';
  }
}
@media (max-width: 1030px) {
  .media-test {
    display: 'tablet';
  }
}
@media (max-width: 1233px) {
  .media-test {
    display: 'desktopSmall';
  }
}
@media (max-width: 1643px) {
  .media-test {
    display: 'desktop';
  }
}
@font-face {
  /*font-weight: 700;*/
  font-style: normal;
  font-family: 'suisseIntlBold';
  src: url("/fonts/SuisseIntlBold/SuisseIntl-Bold.eot") format("eot"), url("/fonts/SuisseIntlBold/SuisseIntl-Bold.woff2") format("woff2"), url("/fonts/SuisseIntlBold/SuisseIntl-Bold.woff") format("woff"), url("/fonts/SuisseIntlBold/SuisseIntl-Bold.ttf") format("truetype");
}
@font-face {
  /*font-weight: 500;*/
  font-style: normal;
  font-family: 'suisseIntlBook';
  src: url("/fonts/SuisseIntlBook/SuisseIntl-Book.eot") format("eot"), url("/fonts/SuisseIntlBook/SuisseIntl-Book.woff2") format("woff2"), url("/fonts/SuisseIntlBook/SuisseIntl-Book.woff") format("woff"), url("/fonts/SuisseIntlBook/SuisseIntl-Book.ttf") format("truetype");
}
@font-face {
  /*font-weight: 300;*/
  font-style: normal;
  font-family: 'suisseIntlMedium';
  src: url("/fonts/SuisseIntlMedium/SuisseIntl-Medium.eot") format("eot"), url("/fonts/SuisseIntlMedium/SuisseIntl-Medium.woff2") format("woff2"), url("/fonts/SuisseIntlMedium/SuisseIntl-Medium.woff") format("woff"), url("/fonts/SuisseIntlMedium/SuisseIntl-Medium.ttf") format("truetype");
}
@font-face {
  font-style: normal;
  font-weight: 400;
  font-family: 'merriweather';
  src: url("/fonts/MerriweatherRegular/Merriweather-Regular.eot") format("eot"), url("/fonts/MerriweatherRegular/Merriweather-Regular.woff2") format("woff2"), url("/fonts/MerriweatherRegular/Merriweather-Regular.woff") format("woff"), url("/fonts/MerriweatherRegular/Merriweather-Regular.ttf") format("truetype");
}
@font-face {
  font-style: italic;
  font-weight: 400;
  font-family: 'merriweather';
  src: url("/fonts/MerriweatherItalic/Merriweather-Italic.eot") format("eot"), url("/fonts/MerriweatherItalic/Merriweather-Italic.woff2") format("woff2"), url("/fonts/MerriweatherItalic/Merriweather-Italic.woff") format("woff"), url("/fonts/MerriweatherItalic/Merriweather-Italic.ttf") format("truetype");
}
@font-face {
  font-style: normal;
  font-weight: 900;
  font-family: 'merriweather';
  src: url("/fonts/MerriweatherBlack/Merriweather-Black.eot") format("eot"), url("/fonts/MerriweatherBlack/Merriweather-Black.woff2") format("woff2"), url("/fonts/MerriweatherBlack/Merriweather-Black.woff") format("woff"), url("/fonts/MerriweatherBlack/Merriweather-Black.ttf") format("truetype");
}
.application {
  padding-top: 35px;
  padding-bottom: 35px;
}
@media (min-width: 544px) {
  .application__container {
    display: flex;
    justify-content: space-between;
  }
}
@media (min-width: 544px) {
  .application__column {
    width: calc((100% - 35px) / 2 - 0.001px);
    flex-grow: 0;
    flex-shrink: 0;
  }
}
.application__column_center {
  text-align: center;
}
.application__title {
  margin: 0;
  font-family: 'merriweather', serif;
  color: #333333;
  font-size: 20px;
  line-height: 24px;
  font-weight: bold;
}
.application__title_center {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}
@media (min-width: 544px) {
  .application__title {
    font-size: 29px;
    line-height: 36px;
  }
}
@media (min-width: 1030px) {
  .application__title {
    font-size: 36px;
    line-height: 42px;
  }
}
.application__subtitle {
  margin: 0;
  margin-top: 25px;
  max-width: 550px;
  color: #333333;
  font-size: 14px;
  line-height: 20px;
  font-family: 'merriweather', serif;
}
@media (min-width: 544px) {
  .application__subtitle {
    font-size: 18px;
    line-height: 25px;
  }
}
@media (min-width: 1030px) {
  .application__subtitle {
    font-size: 20px;
    line-height: 30px;
  }
}
.application__item {
  margin-top: 50px;
  display: flex;
}
.application__number {
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  background-color: #2059F6;
  color: #00BFFF;
  font-size: 29px;
  line-height: 32px;
  font-family: 'suisseIntlBook', sans-serif;
  width: 56px;
  height: 56px;
  border-radius: 50%;
}
.application__name {
  margin: 0;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  color: #333333;
}
@media (min-width: 544px) {
  .application__name {
    font-size: 20px;
    line-height: 25px;
  }
}
.application__text {
  margin-top: 5px;
  margin-bottom: 0;
  font-size: 14px;
  line-height: 20px;
  color: #333333;
}
.application__form {
  margin-top: 40px;
}
@media (min-width: 544px) {
  .application__form {
    margin-top: 0;
  }
}
.application__button {
  margin-top: 50px;
}
.application__recaptcha {
  margin-top: 25px;
  overflow: hidden;
}
.application__agree {
  margin: 10px auto 0;
  max-width: 350px;
  font-size: 11px;
  line-height: 15px;
  text-align: center;
  color: #333333;
}
.application__confidentiality {
  color: #333333;
  transition: opacity .2s;
}
.application__confidentiality:hover {
  opacity: .7;
}
