@import "../app";

.application {
    padding-top: 35px;
    padding-bottom: 35px;

    &__container {

        .mobileBack({
            display: flex;
            justify-content: space-between;
        })
    }

    &__column {

        .mobileBack({
            width: ~'calc((100% - 35px) / 2 - 0.001px)';
            flex-grow: 0;
            flex-shrink: 0;
        });

        &_center {
            text-align: center;
        }
    }

    &__title {
        margin: 0;
        .serif();
        color: #333333;
        font-size: 20px;
        line-height: 24px;
        font-weight: bold;

        &_center {
            text-align: center;
            margin-left: auto;
            margin-right: auto;
        }

        .mobileBack({
            font-size: 29px;
            line-height: 36px;
        });

        .tabletBack({
            font-size: 36px;
            line-height: 42px;
        });
    }

    &__subtitle {
        margin: 0;
        margin-top: 25px;
        max-width: 550px;
        color: #333333;
        font-size: 14px;
        line-height: 20px;
        .serif();

        .mobileBack({
            font-size: 18px;
            line-height: 25px;
        });

        .tabletBack({
            font-size: 20px;
            line-height: 30px;
        });
    }

    &__item {
        margin-top: 50px;
        display: flex;
    }

    &__number {
        margin-right: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        background-color: #2059F6;
        color: #00BFFF;
        font-size: 29px;
        line-height: 32px;
        .sans-serif();
        width: @buttonSize;
        height: @buttonSize;
        border-radius: 50%;
    }

    &__block {
        //
    }

    &__name {
        margin: 0;
        font-weight: bold;
        font-size: 18px;
        line-height: 23px;
        color: #333333;

        .mobileBack({
            font-size: 20px;
            line-height: 25px;
        });
    }

    &__text {
        margin-top: 5px;
        margin-bottom: 0;
        font-size: 14px;
        line-height: 20px;
        color: #333333;
    }

    &__form {
        margin-top: 40px;

        .mobileBack({
            margin-top: 0;
        });
    }

    &__button {
        margin-top: 50px;
    }

    &__recaptcha {
        margin-top: 25px;
        overflow: hidden;
    }

    &__agree {
        margin: 10px auto 0;
        max-width: 350px;
        font-size: 11px;
        line-height: 15px;
        text-align: center;
        color: #333333;
    }

    &__confidentiality {
        color: #333333;
        transition: opacity .2s;

        &:hover {
            opacity: .7;
        }
    }
}
