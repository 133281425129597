@import "../app";

.footer {
    padding-top: 40px;
    padding-bottom: 40px;
    background-color: #232323;
    flex-shrink: 0;

    &__container {
        padding-bottom: 40px;

        .mobileBack({
            display: flex;
            justify-content: space-between;
        });

        &_rules {
            padding-top: 16px;
            border-top: 1px solid hsla(0,0%,100%,.1);
        }
    }

    &__menu {

        .mobileBack({
            width: 216px;
        });
    }

    &__item {
        margin-right: 24px;
        margin-bottom: 20px;
        display: inline-block;
        color: white;
        text-decoration: none;
        transition: color .3s;
        font-size: 14px;
        line-height: 20px;

        .mobileBack({
            font-size: 15px;
            margin-right: 0;
            display: block;
        });

        &:hover {
            color: #999;
        }
    }

    &__rules {
        margin-right: 30px;
        color: white;
        text-decoration: none;
        transition: color .3s;
        font-size: 14px;
        line-height: 20px;

        &:hover {
            color: #999;
        }

        .mobileBack({
            font-size: 15px;
        });
    }

    &__information {

        .mobileBack({
            width: 50%;
        });
    }

    &__text {
        color: #999;
        font-size: 14px;
        line-height: 20px;

        .mobileBack({
            font-size: 15px;
        });

        &_main {
            .mobileBack({
                padding-bottom: 34px;
            });
        }

        &_copyright {
            margin-right: 24px;
        }

        &_who,
        &_copyright,
        &_year {
            display: block;
            margin-top: 20px;

            .mobileBack({
                margin-top: 0;
                display: inline;
            });
        }
    }
}
