@import "app";
@import "includes/keyframes";

*,
*::before,
*::after {
    box-sizing: inherit;
    outline:    none;
}

* {
    -webkit-appearance:          none;
    -moz-appearance:             none;
    appearance:                  none;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
}

html {
    box-sizing: border-box;
}

body {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    height: 0;
    font-style: normal;
    font-weight: 400;
    min-width: 320px;
    margin: 0;
    padding-top: @headerHeightMobile;
    .sans-serif();

    .mobileBack({
        padding-top: @headerHeight;
    });
}
