@import "../app";

.fullscreen {
    flex: 1 0 auto;

    &_center {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

}
