@import "../app";

.container {
    max-width: 1640px;
    padding-left: 20px;
    padding-right: 20px;
    margin-left: auto;
    margin-right: auto;

    &_content {
        max-width: 1240px;
    }
}
